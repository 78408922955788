import systemSettingsFormStore from '@/modules/system-settings/store/system-settings-form-store';
import systemSettingsListStore from '@/modules/system-settings/store/system-settings-list-store';

export default {
    namespaced: true,

    modules: {
        form: systemSettingsFormStore,
        list: systemSettingsListStore,
    },
};
