import ApiService from '@/shared/services/api.service';

export class SystemSettingsService {
    static list(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/settings-registry', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static update(id, data) {
        if (data.setting_type === 'boolean') {
            data.default_value = data.default_value === 'true';
        }
        return new Promise((resolve, reject) => {
            ApiService.patch(`/settings-registry/${id}`, { ...data })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static async listAutocomplete(param = {}) {
        const params = {
            params: {
                limit: 100,
            }
        };

        const response = await ApiService.query('/settings-registry', params);

        if (param?.search) {
            const result = response?.data?.data.filter(item => item.name.toLowerCase().startsWith(param.search));
            return result;
        }

        return response?.data?.data || [];
    }
}
